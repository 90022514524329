import { createRouter, createWebHistory } from 'vue-router'

const HurricaneIanApp = () => import(/* webpackChunkName: "HurricaneIanApp" */ "@/views/HurricaneIanApp")
const ThankYouPage = () => import(/* webpackChunkName: "ThankYouPage" */ "@/views/ThankYouPage")
const Partners = () => import(/* webpackChunkName: "Partners" */ "@/views/Partners")
const Terms = () => import(/* webpackChunkName: "Partners" */ "@/views/Terms.vue")
const Privacy = () => import(/* webpackChunkName: "Partners" */ "@/views/Privacy.vue")

const routes = [
  { path: '/', component: HurricaneIanApp, meta: { title: 'Home' }, },
  { path: '/thank-you', component: ThankYouPage, meta: { title: 'Thank You' }, },
  { path: '/partners', component: Partners, meta: { title: 'Partners' }, },
  { path: '/terms-of-use', component: Terms, meta: { title: 'Terms' }, },
  { path: '/privacy-policy', component: Privacy, meta: { title: 'Privacy' }, }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
