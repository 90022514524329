<template>
  <router-view/>
</template>

<style lang="sass">
@import "assets/style/normalize.css"
@import "assets/style/main"

#app
  font-family: "SharpSans Display No1 Bold", Arial, sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  color: #2c3e50

#nav
  padding: 30px

  a
    font-weight: bold
    color: #2c3e50
</style>
